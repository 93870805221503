import { ILocation } from '../../types';

// TODO: merge with common/locationUtils.ts and move to base/locationUtils.ts

export function getDistance(startPoint?: ILocation, endPoint?: ILocation, precision = 2): number {
  if (!startPoint || !endPoint) return 0;

  const R = 6371; // Radius of the earth in km
  const PI_DIV_180 = Math.PI / 180; // Precomputed constant for conversion

  // Convert degrees to radians
  const deg2rad = (deg: number) => deg * PI_DIV_180;

  const lat1Rad = deg2rad(startPoint.lat);
  const lat2Rad = deg2rad(endPoint.lat);
  const deltaLat = deg2rad(endPoint.lat - startPoint.lat);
  const deltaLon = deg2rad(endPoint.lon - startPoint.lon);

  const sinDeltaLat = Math.sin(deltaLat / 2);
  const sinDeltaLon = Math.sin(deltaLon / 2);

  const a = sinDeltaLat * sinDeltaLat + sinDeltaLon * sinDeltaLon * Math.cos(lat1Rad) * Math.cos(lat2Rad);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return parseFloat((R * c).toFixed(precision));
}
