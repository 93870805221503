import { Units } from '../../../types';

// styles
export const h1CellStyle: IXlsCellStyle = { bg: 'C0C0C0', bold: true, fontSize: 14 };
export const h2CellStyle: IXlsCellStyle = { bg: 'E9E9E9', bold: true };
export const boldCellStyle: IXlsCellStyle = { bold: true };

export const valueCellStyle = (
  v: number,
  threshold = 0,
  lessThanZeroStyle: IXlsCellStyle = {},
  zeroStyle: IXlsCellStyle = {},
  greaterThanZeroStyle: IXlsCellStyle = {},
): IXlsCellStyle => {
  if (v === threshold) {
    return zeroStyle;
  }
  if (v > threshold) {
    return greaterThanZeroStyle;
  }
  return lessThanZeroStyle;
};

export const zeroValueLightFGStyle = (v: number) => valueCellStyle(v, 0, undefined, { fg: 'C0C0C0' });

export interface IXlsCellStyle {
  fg?: string;
  bg?: string;
  bold?: boolean;
  fontSize?: number;
}

export function createCell(
  worksheet: any,
  col: number,
  row: number,
  val: any,
  unit?: Units,
  style?: IXlsCellStyle | ((val: any) => IXlsCellStyle),
) {
  let cell = worksheet.getRow(row + 1).getCell(col + 1);
  cell.value = val;
  switch (unit) {
    case Units.EURO:
      cell.numFmt = '#,##0.00 €';
      break;
    case Units.EUROROUNDED:
      cell.numFmt = '#,##0 €';
      break;
    case Units.PROZENTE:
      cell.numFmt = '#,##0.00 %';
      break;
    case Units.PROZENTEROUNDED:
      cell.numFmt = '#,##0 %';
      break;
    case Units.MINUTEN:
      cell.numFmt = '#,##0 "min"';
      break;
    case Units.STUNDEN:
      cell.numFmt = '#,##0.00 "h"';
      break;
    case Units.STUNDENROUNDED:
      cell.numFmt = '#,##0 "h"';
      break;
    case Units.DAYS:
      cell.numFmt = '#,##0.00 "D"';
      break;
    case Units.DAYSROUNDED:
      cell.numFmt = '#,##0 "d"';
      break;
    case Units.DATUM:
      if (val !== undefined && typeof val === 'string' && val.length > 0) {
        // should be date
        try {
          const d = new Date(Date.parse(val.substring(0, 10)));
          if (d instanceof Date && !isNaN(d.getTime())) {
            cell.value = d;
          } else {
            // console.log('dd', val, cell.value);
          }
        } catch (e) {
          console.log('error while dateconvert', cell.value);
        }
      }
      cell.numFmt = 'mm-dd-yy';
      break;
    case Units.TIME:
      if (val !== undefined && typeof val === 'string' && val.length > 0) {
        // should be date
        try {
          const d = new Date(Date.parse(val.substring(0, 10)));
          if (d instanceof Date && !isNaN(d.getTime())) {
            cell.value = d;
          } else {
            console.log('tt', val, cell.value);
          }
        } catch (e) {
          console.log('error while timeconvert', cell.value);
        }
      }
      cell.numFmt = 'h:mm';
      break;
    case Units.EURO_PRO_STUNDE:
    case Units.EURO_PRO_ZIMMER:
    case Units.EURO_PRO_TERMIN:
    case Units.FLOAT:
      cell.numFmt = '#,##0.00';
      break;
    case Units.TERMINE:
    case Units.PATIENTEN:
    case Units.IMPLANTATE:
    case Units.INTEGER:
      if (isNaN(cell.value)) {
        cell.value = 0;
      }
      cell.numFmt = '#,##0';
      break;
    case Units.ENTFERNUNG:
      cell.numFmt = '#,##0.00 "km"';
      break;
    case Units.NONE:
      break;
  }
  let useStyle: IXlsCellStyle = {};
  if (typeof style === 'function') {
    useStyle = style(val);
  } else if (style) {
    useStyle = style;
  }
  if (useStyle) {
    if (useStyle?.fg || useStyle?.bg) {
      // background color is foreground color of fill
      if (useStyle.bg) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: useStyle.bg },
        };
      }
      // foreground color is font color
      if (useStyle.fg) {
        cell.font = {
          color: { argb: useStyle.fg },
        };
      }
    }
    if (useStyle?.bold) {
      cell.font = {
        ...cell.font,
        bold: true,
      };
    }
    if (useStyle?.fontSize) {
      cell.font = {
        ...cell.font,
        size: useStyle.fontSize,
      };
    }
  }
}

export function mergeCells(worksheet: any, rowstart: number, colstart: number, rowend: number, colend: number) {
  try {
    worksheet.mergeCells(rowstart + 1, colstart + 1, rowend + 1, colend + 1);
  } catch (err) {
    console.error(`failed to merge cells`, rowstart, colstart, rowend, colend);
  }
}

export function setRowOutlineGripOnTop(ws: any) {
  ws.properties.outlineProperties = {
    summaryBelow: false,
  };
}

export function setRowOutlineLevel(worksheet: any, row: number, level: number) {
  if (level > 0) {
    worksheet.getRow(row + 1).outlineLevel = level;
  }
}

export function setColumnOutlineLevel(worksheet: any, column: number, level: number) {
  if (level > 0) {
    worksheet.getColumn(column + 1).outlineLevel = level;
  }
}

export function finalizeSimpleXLSXWithMetadata(
  workbook: any,
  worksheet: any,
  metaData: any,
  maxCol: number,
  maxRow: number,
  widths: { wpx: number }[],
) {
  if (metaData) {
    createMetaWorkSheet(workbook, metaData);
  }
  finalizeWorksheet(worksheet, maxCol, maxRow, widths);
}

export function finalizeWorksheet(worksheet: any, maxCol: number, maxRow: number, widths: { wpx: number }[]) {
  setColumnWidths(worksheet, widths);
  setRange(worksheet, maxCol, maxRow);
}

export function getWorkSheetNames(workbook: any) {
  const names: string[] = [];
  workbook.eachSheet((worksheet: any, name: string) => {
    names.push(name);
  });
  return names;
}

export function createEmptyWorkSheet(workbook: any, name: string) {
  // remove forbidden chars: * ? : \ / [ ]
  name = name?.replace(/[*?:\\/[\]]/g, '_') || '-';
  return workbook.addWorksheet(name);
}

/**
 * Creates a worksheet with metadata information.
 * @param workbook The workbook where the worksheet will be added.
 * @param meta The metadata object to be added to the worksheet.
 */
export function createMetaWorkSheet(workbook: any, meta: Record<string, any>): void {
  const worksheetTitle = 'Metadaten';
  const worksheet: any = workbook.addWorksheet(worksheetTitle, { properties: { tabColor: { argb: '337ab7' } } });

  // Setting up column titles and widths
  worksheet.columns = [
    { header: 'Information', key: 'key', width: 40 },
    { header: 'Wert', key: 'value', width: 80 },
  ];

  // Formatting the header for better visibility
  worksheet.getRow(1).font = { bold: true };

  // Adding metadata entries to the worksheet
  Object.entries(meta).forEach(([key, value], index) => {
    worksheet.addRow({ key, value });
    // Optional: Adjust cell formatting here
  });

  // Optional: Auto-adjust column widths (not directly supported by exceljs, but you can implement a function for it)
  autoAdjustColumnWidths(worksheet);
}

// Example of an auto-adjust column widths function (implementation depends on your needs)
function autoAdjustColumnWidths(worksheet: any): void {
  // @ts-ignore
  worksheet.columns.forEach(column => {
    let maxLength = 0;
    // @ts-ignore
    column.eachCell({ includeEmpty: true }, cell => {
      let cellLength = cell.value ? cell.value.toString().length : 10;
      if (cellLength > maxLength) maxLength = cellLength;
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });
}

export function setColumnWidths(worksheet: any, widths: { wpx: number }[]) {
  if (widths) {
    worksheet.columns = widths.map(w => ({ width: w.wpx * 0.2 }));
  }
  return [];
}

export function setRange(worksheet: any, c: number, r: number) {
  worksheet.properties.columnCount = c;
  worksheet.properties.rowCount = r;
}

export async function getXLSXAsBase64String(wb: any) {
  const buffer = await wb.xlsx.writeBuffer();
  return buffer.toString('base64');
}

export async function getXLSXFromBuffer(wb: any, buffer: any) {
  return await wb.xlsx.load(buffer);
}
