import { metricsApi } from '@/services/data';
import { authStore } from '@/state/authStore';
import { viewStateStore, ViewState } from '@/state/viewStateStore';
import { createDirectStore } from 'direct-vuex';
import { maxBy, minBy, map } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import { last30Days } from '@rose/common-ui';
import { roseDayjs } from '../../../base';
import {
  IPatientLocation,
  IProfile,
  ITeam,
  LocationsSearchDateFilter,
  LocationsSearchVersicherungFilter,
  IPatientenLocationsQueryParams,
  IPatientLocationWithDetails,
} from '@rose/types';
import { clientStateStore } from '@/state/clientStateStore';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { store, rootActionContext } = createDirectStore({
  state: () => ({
    locations: [] as IPatientLocation[],
    profile: null as IProfile | null,
    locationsLoading: false,
    versicherungFilter: LocationsSearchVersicherungFilter.Alle,
    dateFilter: LocationsSearchDateFilter.Alle,
    umsatzFilter: 0,
    date: {
      startDate: roseDayjs(last30Days[0]).format('YYYY-MM-DD'),
      endDate: roseDayjs(last30Days[1]).format('YYYY-MM-DD'),
    },
    teamFilter: null as ITeam | null,
  }),
  getters: {
    locations(state): IPatientLocation[] {
      return state.locations;
    },
    locationsLoading(state) {
      return state.locationsLoading;
    },
    date(state) {
      return state.date;
    },
    dateFilter(state) {
      return state.dateFilter;
    },
    versicherungFilter(state) {
      return state.versicherungFilter;
    },
    teamFilter(state) {
      return state.teamFilter;
    },
    mapCenter(state) {
      if (state.profile?.location?.lat && state.profile?.location?.lat) {
        return { lat: state.profile.location.lat, lng: state.profile.location.lon };
      } else {
        const latMin = minBy(state.locations, l => l.lat)?.lat || 0;
        const latMax = maxBy(state.locations, l => l.lat)?.lat || 0;
        const lonMin = minBy(state.locations, l => l.lon)?.lon || 0;
        const lonMax = maxBy(state.locations, l => l.lon)?.lon || 0;
        const c = { lat: latMin + (latMax - latMin) / 2, lng: lonMin + (lonMax - lonMin) / 2 };
        return c;
      }
    },
  },
  mutations: {
    setLocations(state, locations: IPatientLocation[]) {
      state.locations = locations;
    },
    setProfile(state, profile: IProfile) {
      state.profile = profile;
    },
    setLocationsLoading(state, bool: boolean) {
      state.locationsLoading = bool;
    },
    setDate(state, date) {
      state.date = date;
    },
    setDateFilter(state, dateFilter) {
      state.dateFilter = dateFilter;
    },
    setVersicherungFilter(state, versicherungFilter) {
      state.versicherungFilter = versicherungFilter;
    },
    setTeamFilter(state, teamFilter) {
      state.teamFilter = teamFilter;
    },
    setUmsatzFilter(state, umsatzFilter) {
      state.umsatzFilter = umsatzFilter;
    },
  },
  actions: {
    async getData(context) {
      const { state, dispatch, commit } = rootActionContext(context);

      commit.setLocationsLoading(true);

      let queryParams: IPatientenLocationsQueryParams = { team: state.teamFilter?.id };

      if (state.versicherungFilter !== LocationsSearchVersicherungFilter.Alle) {
        queryParams = { ...queryParams, versicherung: state.versicherungFilter };
      }

      if (
        state.dateFilter === LocationsSearchDateFilter.LetzterTermin ||
        state.dateFilter === LocationsSearchDateFilter.Neupatienten
      ) {
        queryParams = {
          ...queryParams,
          from: state.date?.startDate,
          to: state.date?.endDate,
          filter: state.dateFilter,
        };
      }

      await viewStateStore.dispatch.addToViewState((queryParams as ViewState) || {});

      const locations = await metricsApi.patientenKarte.getPatientenLocations(queryParams);
      const profile = authStore.getters.profile;
      if (profile) {
        commit.setProfile(profile);
      }
      console.log(`patientenkarte data`, locations, profile);

      const locationsWithDetails: IPatientLocationWithDetails[] = map(locations.locations, l => ({
        ...l,
        name: '-',
        vorname: '-',
        patid: l.patid,
      }));

      await clientStateStore.getters.names.augment(locationsWithDetails, undefined, { withPAR: true });

      commit.setLocations(locationsWithDetails);
      commit.setLocationsLoading(false);
    },
    setDate(context, data: { startDate: string; endDate: string }) {
      const { state, dispatch, commit } = rootActionContext(context);
      commit.setDate(data);
      void dispatch.getData();
    },
    setDateFilter(context, filter: LocationsSearchDateFilter) {
      const { state, dispatch, commit } = rootActionContext(context);
      commit.setDateFilter(filter);
      void dispatch.getData();
    },
    setVersicherungFilter(context, filter: LocationsSearchVersicherungFilter) {
      const { state, dispatch, commit } = rootActionContext(context);
      commit.setVersicherungFilter(filter);
      void dispatch.getData();
    },
    setTeamSelected(context, team: ITeam) {
      const { state, dispatch, commit } = rootActionContext(context);
      commit.setTeamFilter(team);
      void dispatch.getData();
    },
  },
});

export const patientenKarteStore = store;
